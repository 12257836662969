import React from "react";
import { useState } from "react";
import { useEffect } from "react";
// import './years.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Years = () => {
  const [years, setyears] = useState([]);
  const [lesson, setlesson] = useState([]);
  const [error, setError] = useState(null);
  const [question, setquestion] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearId, setSelectedYear_Id] = useState(null);
  const [selectedLessonId, setSelectedLesson_Id] = useState(null);

  const [selectedlesson, setSelectedlesson] = useState(null);
  // const [selctedQuestion, setselectedQuestion] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  // const [ answerimagelist , setanswerimagelist]=useState([])
  const navigate = useNavigate();

  const location = useLocation();
  const { firstname } = location.state || {};

  let [apiselectedQuestions, apisetSelectedQuestions] = useState([]);

  const fetchYears = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/allyears`, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
          "Content-Type": "application/json",
          jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
        },
      });
      if (!response.ok) {
        console.log(response.json());
        throw new Error("Failed to fetch data");
      }
      const result = await response.json();
      setyears(result.data);
      // Assuming `data` is the key for questions
    } catch (err) {
      setError(err.message);
      console.error("Error fetching data:", err);
    }
  };

  const handleItemClick = async (id, year) => {
    console.log("gdvjhbjc,ndhjghscvbnldxkjmsncz x");
    try {
      let item = {
        YearId: id,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/lessionofyear`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
          body: JSON.stringify(item),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      let result = await response.json();
      setlesson(result.data);
      // console.log(result.data.fields)
      setSelectedYear(year);
      setSelectedYear_Id(id); // Assuming `data` is the key for questions
    } catch {
      console.log("");
    }
  };

  const handleItemQuestion = async (id, lesson) => {
    console.log("gdvjhbjc,ndhjghscvbnldxkjmsncz x");
    try {
      let item = {
        LessionId: id,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/questionoflession`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
          body: JSON.stringify(item),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      let result = await response.json();
      // result.data.map((item)=> (
      //     console.log(item.fields.imageList);
      //     item.fields.imageList.map((p)=>(
      //         console.log(p)
      //     ))
      //     ))
      result.data.map((item) => {});
      console.log(id);
      setquestion(result.data);
      setSelectedlesson(lesson);
      setSelectedLesson_Id(id); // Assuming `data` is the key for questions
    } catch {
      console.log("");
    }
  };
  // const selectquestion = (item) => {
  //     console.log(selectedLessonId, selectedYearId ,item)
  //   };

  // const handleCheckboxChange = (question_Id) => {
  //     setSelectedQuestions(prevSelected => {
  //         // Check if the question is already selected
  //         const isSelected = prevSelected.some(item => item.question_Id === question_Id);
  //         setIsButtonVisible(true);

  //         if (isSelected) {
  //             // If already selected, remove it
  //             return prevSelected.filter(item => item.question_Id !== question_Id);

  //         } else {
  //             // If not selected, add it
  //             const selectedData = {

  //                 "QuestionID": question_Id,
  //                 "LessonId": selectedLessonId,
  //                 "YearId": selectedYearId,
  //             };
  //             return [...prevSelected, selectedData];
  //         }

  //     });
  // };
  const handleCheckboxChange = (questionId) => {
    setSelectedQuestions((prevSelected) => {
      // Check if the question is already selected
      const isSelected = prevSelected.some(
        (item) => item.questionId === questionId
      );
      setIsButtonVisible(true);

      if (isSelected) {
        // If already selected, remove it
        return prevSelected.filter((item) => item.questionId !== questionId);
      } else {
        // If not selected, add it
        const selectedData = {
          QuestionID: questionId,
          LessonId: selectedLessonId,
          YearId: selectedYearId,
        };
        return [...prevSelected, selectedData];
      }
    });
  };

  const saveSelectedQuestion = async () => {
    const handleClick = () => {
      setIsButtonVisible(true);
    };

    handleClick();

    console.log("pvn");
    console.log(apiselectedQuestions);
    let item = { Questionlist: selectedQuestions };
    console.log(item);

    try {
      let item = { Questionlist: selectedQuestions };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/selectedquestion`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
          body: JSON.stringify(item),
        }
      );

      if (!response.ok) {
        console.log(response.json());
        console.log("erooe");
        throw new Error("Failed to fetch data");
      }
      let result = await response.json();
      console.log(result);
    } catch (err) {
      setError(err.message);
      console.log("hdhshgdh");
    }
  };

  const selected = () => {
    navigate("/selected");
  };

  useEffect(() => {
    fetchYears();
  }, []);

  // // const deleteQuestion = (id) => {
  // //     // Find the question to delete
  // //     const questionToDelete = questions.find(question => question.pk === id);
  // //     if (questionToDelete) {
  // //         // Remove the question from questions array
  // //         const updatedQuestions = questions.filter(question => question.pk !== id);
  // //         // Add the deleted question to deletedQuestions array
  // //         setDeletedQuestions([...deletedQuestions, questionToDelete]);
  // //         // Update the questions state
  // //         setQuestions(updatedQuestions);
  // //     }

  // console.log(years)

  console.log(lesson.data);
  return (
    <div className="parent">
      <div className="parent-main flex">
        <div className="yeardashboard">
          <h2 className="heading">Years of MathDingo</h2>
          {years.map((item) => (
            <div className="year-box-parent vertical" key={item.pk}>
              <div
                className="year-box flex"
                onClick={() => {
                  handleItemClick(item.pk, item.fields.year);
                }}
              >
                <p className="year-name">{item.fields.year}</p>
                <p className="viewdetails"> view Details</p>
              </div>
            </div>
          ))}
          {error && <p>Error: {error}</p>}
          {responseData && <ResponseComponent data={responseData} />}
        </div>

        <div className="lessondashboard">
          <h1 className="heading">{selectedYear}</h1>
          <div className="lesson vertical">
            {lesson.map((item) => (
              <div className="vertical" key={item.pk}>
                <h2
                  className="lesson-name"
                  onClick={() => {
                    handleItemQuestion(item.pk, item.fields.lession);
                  }}
                >
                  {item.fields.lession}
                </h2>
                <div className="seperation"></div>
              </div>
            ))}

            <div className="scroll-indicator">Scroll down for more</div>
            <h1>Welcome, {firstname}!</h1>
          </div>
        </div>
        <div className="questiondashboard">
          <h1 className="heading">{selectedlesson}</h1>
          <div className="questions vertical">
            {question.map((item) => (
              <div className="quesdesign" key={item.pk}>
                <div className="questionbox flex">
                  <p>questions :</p>
                  <h2>{item.fields.question}</h2>
                </div>
                <div className="answebox flex">
                  <p>answer :</p>
                  {/* {item.fields.imageList.map((item)=>(
                                ))} */}
                  {/* <p>answer</p> */}
                  <img src={item.fields.imageList[item.fields.answear]}></img>
                  {/* <>{item.fields.imageList[item.fields.answear]}</h2> */}
                </div>
                <div className="imagelist flex">
                  <p>imagelist :</p>
                  {item.fields.imageList.map((item) => (
                    <img src={item}></img>
                  ))}
                </div>
                <div className="questionlist flex">
                  <p>questionimage list :</p>
                  {item.fields.questionimagelist.map((item) => (
                    <img src={item}></img>
                  ))}
                </div>
                {/* <button onClick={() => {selectquestion(item.pk)}} >select</button> */}
                <div>
                  {/* <input
                                        type="checkbox"
                                        checked={selectedQuestions.some(q => q.question_Id === item.pk)}
                                        onChange={() => handleCheckboxChange(item.pk)}
                                    />
                                    <label>Select</label> */}
                  <input
                    type="checkbox"
                    checked={selectedQuestions.some(
                      (q) => q.QuestionID === item.pk
                    )}
                    onChange={() => handleCheckboxChange(item.pk)}
                  />
                  <label>Select</label>
                </div>
              </div>
            ))}
            {isButtonVisible && (
              <button
                onClick={() => {
                  saveSelectedQuestion();
                }}
              >
                save
              </button>
            )}
            <button onClick={selected}>selected question</button>
          </div>
        </div>

        {/* <div>
                <h1>{selectedlesson}</h1>
                {question.map((item) => (
                            <div key={item.pk}>
                                <h2>{item.fields.question}</h2>
                                <button onClick={() => {selectquestion(item.pk)}}>select</button>
                                
                            </div>
                        ))}
                </div> */}

        {/* <div>
                    <h1>selected questio list</h1>
                    <h2>{selctedQuestion}</h2>
                </div> */}
      </div>
    </div>

    //     <div>
    //   {items.map(item => (
    //     <div key={item.pk}>
    //       <h1>{item.pk}</h1>
    //       <h2 onClick={() => handleItemClick(item.pk)}>
    //         {item.fields.year}
    //       </h2>
    //       {/* Render lessons only if the current year is selected */}
    //       {selectedYear === item.fields.year && (
    //         <div>
    //           {item.lessons.map(lesson => (
    //             <div key={lesson.pk}>
    //               <h2>{lesson.fields.lesson}</h2>
    //             </div>
    //           ))}
    //         </div>
    //       )}
    //     </div>
    //   ))}
    // </div>
  );

  const ResponseComponent = ({ data }) => {
    return (
      <div>
        <h3>Item Details</h3>
        {/* Render the response data */}
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };
};

export default Years;
