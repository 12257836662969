import React, { useState, useEffect } from 'react';
import './questions.css';
import { useLocation } from 'react-router-dom';

const Question = () => {
    const [question, setQuestion] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [unselectedQuestions, setUnselectedQuestions] = useState([]);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const { LessonId } = location.state || {};
    const { LessonName } = location.state || {};
    const { yearId } = location.state || {};

    const fetchQuestions = async () => {
        try {
            setLoading(true);
            const item = { LessionId: LessonId };
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/questionoflession`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
                    'Content-Type': 'application/json',
                    'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw'
                },
                body: JSON.stringify(item)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const result = await response.json();
            console.log('Questions fetched successfully:', result.data);
            setQuestion(result.data);

            // Initialize selected and unselected questions based on `isAdded` field
            const preSelectedQuestions = result.data
                .filter(item => item.fields.isAdded === '1')
                .map(item => ({
                    "QuestionID": item.pk,
                    "LessonId": LessonId,
                    "YearId": yearId
                }));

            const preUnselectedQuestions = result.data
                .filter(item => item.fields.isAdded !== '1')
                .map(item => ({
                    "QuestionID": item.pk,
                    "LessonId": LessonId,
                    "YearId": yearId
                }));

            setSelectedQuestions(preSelectedQuestions);
            setUnselectedQuestions(preUnselectedQuestions);
            setIsButtonVisible(preSelectedQuestions.length > 0);
        } catch (err) {
            setError(err.message);
            console.error('Error fetching questions:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = async (questionId) => {
        const isSelected = selectedQuestions.some(q => q.QuestionID === questionId);

        if (isSelected) {
            // Remove from selected questions
            setSelectedQuestions(prevSelected => prevSelected.filter(item => item.QuestionID !== questionId));

            // Add to unselected questions
            const questionToRemove = selectedQuestions.find(item => item.QuestionID === questionId);
            setUnselectedQuestions(prevUnselected => [...prevUnselected, questionToRemove]);

            // Call delete API
            try {
                setLoading(true);
                console.log('Sending delete request with body:', { "QuestionID": questionId });
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/deleteQuestion`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
                        'Content-Type': 'application/json',
                        'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw'
                    },
                    body: JSON.stringify({ "questionId": questionId })
                });

                const result = await response.json();
                console.log('Delete API response:', result);

                if (!response.ok) {
                    throw new Error('Failed to delete question');
                }

                console.log('Question deleted successfully');
                // alert('Question deleted successfully');
            } catch (err) {
                setError(err.message);
                console.error('Error deleting question:', err);
            } finally {
                setLoading(false);
                fetchQuestions(); // Refresh data
            }
        } else {
            // Add to selected questions
            setSelectedQuestions(prevSelected => [
                ...prevSelected,
                {
                    "QuestionID": questionId,
                    "LessonId": LessonId,
                    "YearId": yearId
                }
            ]);

            // Remove from unselected questions
            setUnselectedQuestions(prevUnselected => prevUnselected.filter(item => item.QuestionID !== questionId));

            setIsButtonVisible(true);

            // Call save API directly
            try {
                setLoading(true);
                const item = { "Questionlist": [...selectedQuestions, { "QuestionID": questionId, "LessonId": LessonId, "YearId": yearId }] };
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/selectedquestion`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
                        'Content-Type': 'application/json',
                        'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw'
                    },
                    body: JSON.stringify(item)
                });

                const result = await response.json();
                console.log('Save API response:', result);

                if (!response.ok) {
                    throw new Error('Failed to save selected questions');
                }

                console.log('Selected questions saved successfully');
                // alert('Selected questions saved successfully');
            } catch (err) {
                setError(err.message);
                console.error('Error saving selected questions:', err);
            } finally {
                setLoading(false);
                fetchQuestions(); // Refresh data
            }
        }
    };



    useEffect(() => {
        fetchQuestions();
    }, [LessonId]);

    return (
        <div style={{ position: 'relative' ,background:"#FFFDF5",}}>
            <img src="http://34.129.177.103/media/adminimage/Frame1141.png" alt="Logo" style={{
                width: "250px",
                height: "auto",
                background:"#FFFDF5",
            }} />
            <div className='questiondashboard'>
                {loading && <div className='loading'>Loading...</div>}
                <h1 className='heading'>{LessonName}</h1>
                <div className='questions vertical'>
                    {question.map((item) => (
                        <div className='quesdesign' key={item.pk}>
                            <div className='questionbox flex rounded-md bg-button-bg p-5'>
                                {/* <p>Question:</p> */}
                                <h2 className='text-white text-xl font-bold'>{item.fields.question}</h2>
                            </div>
                            <div className='questionlist flex'>
                                <p>Question image list:</p>
                                {item.fields.questionimagelist.map((imgSrc, index) => (
                                    <img key={index} src={imgSrc} alt={`Question Image ${index}`} />
                                ))}
                            </div>
                            <div className='imagelist flex'>
                                <p>Image list:</p>
                                {item.fields.imageList.map((imgSrc, index) => (
                                    <img key={index} src={imgSrc} alt={`Image ${index}`} />
                                ))}
                            </div>
                            <div className='answebox flex flex-col items-center rounded-md bg-button-bg p-5' style={{
                                backgroundColor: "#D9EA49",
                            }}>
                                <p>Answer:</p>
                                <img src={item.fields.imageList[item.fields.answear]} alt="Answer" />
                            </div>
                            {/* <div className='checkbox-container'>
                            <input
                                type="checkbox"
                                checked={selectedQuestions.some(q => q.QuestionID === item.pk)}
                                onChange={() => handleCheckboxChange(item.pk)}
                            />
                            <label
                                className={selectedQuestions.some(q => q.QuestionID === item.pk) ? 'checkbox-label checked' : 'checkbox-label unchecked'}
                            >
                                {selectedQuestions.some(q => q.QuestionID === item.pk) ? 'Unselect' : 'Select'}
                            </label>
                        </div> */}
                            <div className='checkbox-container'>
                                <input
                                    type="checkbox"
                                    checked={selectedQuestions.some(q => q.QuestionID === item.pk)}
                                    onChange={() => handleCheckboxChange(item.pk)}
                                />
                                <label className={`checkbox-label ${selectedQuestions.some(q => q.QuestionID === item.pk) ? 'checked' : 'unchecked'}`}>
                                {selectedQuestions.some(q => q.QuestionID === item.pk) ? 'Unselect' : 'Select'}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
            {error && <div className='error'>{error}</div>}
        </div>
        </div >
    );
};

export default Question;

