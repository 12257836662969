import './App.css';
import Login from './login';
import Signup from './signup';
import Dashboard from './dashboard';
import Question from './question';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Years from './years';
import Insight from './Insight';
import StudentDetails from './StudentDetails';
import StudentReport from './StudentReport';
import AssignTime from './AssignTime';
import Generate from './Generate';
import RequestTime from './RequestTime';
import Lessons from './Lessons';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route index element={<Login/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route  path="/signup"element={<Signup/>}/>
      <Route  path="/dashboard"element={<Dashboard/>}/>
      <Route  path="/question"element={<Question/>}/>
      <Route  path="/insight"element={<Insight/>}/>
      <Route  path="/generate"element={<Generate/>}/>
      <Route  path="/assign"element={<AssignTime/>}/>
      <Route path="/student/:id" element={<StudentDetails />} />
      <Route path="/student/:id/report/:lessonId" element={<StudentReport />} /> 
      <Route path="/requesttime/:userid" element={<RequestTime/>}/>
      <Route path="/lessons" element={<Lessons />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
