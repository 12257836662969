import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./DashBrd.css";
import Sidebar from "./Sidebar";

const Lessons = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState(new Set());
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const lessonsPerPage = 8; // Number of lessons to display per page
  const navigate = useNavigate();
  const location = useLocation();
  const { yearId, yearName } = location.state || {}; // Get the yearId and yearName from the state
  const [trigger, setTrigger] = useState(false);
  const { firstname } = location.state || {};

  const handleItemQuestion = (id, lesson) => {
    navigate("/question", {
      state: { yearId: yearId, LessonId: id, LessonName: lesson },
    });
  };

  const fetchLessons = async () => {
    console.log("yearid", yearId, "lksdfjlks", yearName);
    try {
      const item = { YearId: yearId };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/lessionOfYearAdmin`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "YOUR_JWT_TOKEN",
          },
          body: JSON.stringify(item),
        }
      );
      const result = await response.json();
      setLessons(result.data || []);
    } catch (err) {
      console.error("Error fetching lessons:", err);
      setError("Failed to fetch lessons.");
    }
  };

  useEffect(() => {
    fetchLessons();
  }, [yearId,trigger]);
  
  console.log("first",firstname)
  const handleToggleLessonSelection = async (lessonId, isSelect) => {
    try {
      const headers = {
        Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
        "Content-Type": "application/json",
        jwt: "YOUR_JWT_TOKEN",
      };

      if (isSelect === "1") {
        // Unselect lesson
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/deleteLesson`,
          { Lessonid: lessonId },
          { headers }
        );
        setSelectedLessons((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.delete(lessonId);
          return newSelected;
        });
      } else {
        // Select lesson
        const lessonList = Array.from(selectedLessons).map((id) => ({
          YearId: yearId,
          LessonId: id,
        }));
        lessonList.push({ YearId: yearId, LessonId: lessonId });

        await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/selectedlesson`,
          { Lessonlist: lessonList },
          { headers }
        );
        setSelectedLessons((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.add(lessonId);
          return newSelected;
        });
      }
    } catch (error) {
      console.error("Error toggling lesson selection:", error);
      setError("Failed to toggle lesson selection.");
    }
    setTrigger(prev => !prev); 
  };

  // Calculate the current lessons to display
  const indexOfLastLesson = currentPage * lessonsPerPage;
  const indexOfFirstLesson = indexOfLastLesson - lessonsPerPage;
  const currentLessons = lessons.slice(indexOfFirstLesson, indexOfLastLesson);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(lessons.length / lessonsPerPage);
  const handleInsightRedirect = () => {
    navigate("/insight");
  };
  return (
    <div className="parent bg-[]">
      <div className="dashboard-container">
        <div className="sidebar">
          <Sidebar
           handleInsightRedirect={handleInsightRedirect}
          />
        </div>
      </div>
      <div className="parent-main vertical">
        <h1 className="user-name">{firstname}</h1>
        <div className="lessondashboard">
          <h1 className="headings">{yearName}</h1>
          <div className="lesson vertical">
            {currentLessons.map((item) => (
              <div className="vertical" key={item.pk}>
                <div className="lesson-item">
                  <h2 className="lesson-named">
                    <p className="w-[20%]">{item.fields.lession}</p>
                    <button
                      className="viewdetails w-[100px]"
                      onClick={() =>
                        handleToggleLessonSelection(item.pk, item.fields.isSelect)
                      }
                    >
                      {item.fields.isSelect === "1" ? "Unselect" : "Select"}
                    </button>
                    <p
                      className="viewdetails"
                      onClick={() =>
                        handleItemQuestion(item.pk, item.fields.lession)
                      }
                    >
                      view Details
                    </p>
                  </h2>
                </div>
                <div className="seperation"></div>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`page-button ${currentPage === index + 1 ? "active" : ""}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
