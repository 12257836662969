  // import React, { useState, useEffect } from 'react';

  // const Generate = () => {
  //   const [yearList, setYearList] = useState([]);
  //   const [lessonTopics, setLessonTopics] = useState([]);
  //   const [questionType,setQuestionType]=useState([]);

    
  //   const [questionsAndOptions, setQuestionsAndOptions] = useState([]);

  //   const [questionSelectedType,setQustionSelectedType]=useState('');
  //   const [selectedLesson,setSelectedLesson]=useState('');
  //   const [selectedLessonId,setSelectedLessonId]=useState('');
  //   const [selectedYearID, setSelectedYearID] = useState('');
  //   const [selectedYear, setSelectedYear] = useState('');
  //   const [selectedLevel, setSelectedLevel] = useState('');

  //   const levels = ["Easy", "Medium", "Hard"];
  //   useEffect(() => {
  //     const fetchYears = async () => {
  //       try {
  //         const response = await fetch('http://34.129.177.103/apiadmin/genratequestionYeardata', {
  //           method: 'POST',
  //           headers: {
  //             'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
  //             'Content-Type': 'application/json',
  //             'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw'
  //           },
  //         });

  //         const data = await response.json();
  //         if (data.status_code === "200") {
  //           setYearList(data.yearlist);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching years: ", error);
  //       }
  //     };

  //     fetchYears();
  //   }, []);

  //   useEffect(() => {
  //     if (selectedYearID) {
  //       const fetchLessonTopics = async () => {
  //         try {
  //           const response = await fetch('http://34.129.177.103/apiadmin/getLessontopicList', {
  //             method: 'POST',
  //             headers: {
  //               'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
  //               'Content-Type': 'application/json',
  //               'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw',
  //             },
  //             body: JSON.stringify({ yearId: selectedYearID }),
  //           });

  //           const data = await response.json();
  //           console.log(data)
  //           if (data.status_code === "200") {
  //             setLessonTopics(data.yearlist); // Assuming response has the same structure
  //           }
  //         } catch (error) {
  //           console.error("Error fetching lesson topics: ", error);
  //         }
  //       };

  //       fetchLessonTopics();
  //     }
  //   }, [selectedYearID]);

  //   useEffect(() => {
  //     if (questionType) {
  //       const fetchQuestionType = async () => {
  //         try {
  //           const response = await fetch('http://34.129.177.103/apiadmin/getQuestionTypeList', {
  //             method: 'POST',
  //             headers: {
  //               'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
  //               'Content-Type': 'application/json',
  //               'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw',
  //             },
  //             body: JSON.stringify({ lessonId: selectedLessonId }),
  //           });

  //           const data = await response.json();
  //           console.log("questionType INSIDE ",data)
  //           if (data.status_code === "200") {
  //             setQuestionType(data.yearlist); // Assuming response has the same structure
  //           }
  //         } catch (error) {
  //           console.error("Error fetching lesson topics: ", error);
  //         }
  //       };

  //       fetchQuestionType();
  //     }
  //   }, [selectedLessonId]);

  //   console.log("quesiotnt outside",questionType)
  //   const generateQuestions = async () => {
  //     const apiUrl = 'http://34.129.177.103/apiadmin/geminiApi';

  //     const requestBody = {
  //       content: `I want 1 question based on ${selectedLessonTopic} for level ${selectedLevel} for the year with ID ${selectedYearID} on the basis of the VIC Australian curriculum. Don't generate anything extra at the start or end; just give me the question and four options.`
  //     };

  //     try {
  //       const response = await fetch(apiUrl, {
  //         method: 'POST',
  //         headers: {
  //           'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
  //           'Content-Type': 'application/json',
  //           'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw'
  //         },
  //         body: JSON.stringify(requestBody)
  //       });

  //       const data = await response.json();
  //       if (data && data.data && data.data.candidates && data.data.candidates.length > 0) {
  //         const generatedData = data.data.candidates[0].content.parts[0].text.split('\n\n').filter(q => q);
  //         const questionsWithOptions = [];

  //         for (let i = 0; i < generatedData.length; i += 2) {
  //           if (generatedData[i + 1]) {
  //             questionsWithOptions.push({
  //               question: generatedData[i],
  //               options: generatedData[i + 1].split('\n') 
  //             });
  //           }
  //         }

  //         setQuestionsAndOptions(questionsWithOptions);
  //       } else {
  //         console.error('No valid data returned from API');
  //         setQuestionsAndOptions([]); // Reset questions if no valid data
  //       }
  //     } catch (error) {
  //       console.error('Error generating questions:', error);
  //       setQuestionsAndOptions([]); // Reset questions on error
  //     }
  //   };

  //   const saveQuestion = async (question) => {
  //     const apiUrl = 'http://34.129.177.103/apiadmin/saveQuestion';

  //     const requestBody = {
  //       question: question
  //     };

  //     try {
  //       const response = await fetch(apiUrl, {
  //         method: 'POST',
  //         headers: {
  //           'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
  //           'Content-Type': 'application/json',
  //           'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw'
  //         },
  //         body: JSON.stringify(requestBody)
  //       });

  //       const data = await response.json();
  //       if (data.status_code === "200") {
  //         alert('Question saved successfully!');
  //       } else {
  //         alert('Failed to save question. Please try again.');
  //       }
  //     } catch (error) {
  //       console.error('Error saving question:', error);
  //       alert('Error saving question. Please try again.');
  //     }
  //   };

  //   const handleSave = () => {
  //     if (questionsAndOptions.length > 0) {
  //       saveQuestion(questionsAndOptions[0].question);
  //     } else {
  //       alert('No question available to save.');
  //     }
  //   };
  
  //   return (
  //     <div className="p-4">
  //       <h1 className="text-xl font-bold mb-4">Generate Question</h1>

  //       <div className="mb-4">
  //         <label className="block text-gray-700">Select Year:</label>
  //         <select
  //           value={selectedYearID}
  //           onChange={(e) => {
  //             setSelectedYearID(e.target.value)}}
  //           className="mt-2 p-2 border rounded w-full"
  //         >
  //           <option value="">-- Select Year --</option>
  //           {yearList.map((year) => (
  //             <option key={year.yearID} value={year.yearID}>{year.year}</option>
  //           ))}
  //         </select>
  //       </div>

  //       <div className="mb-4">
  //         <label className="block text-gray-700">Select Lesson Topic:</label>
  //         <select
  //           value={selectedLessonId}
  //           onChange={(e) => setSelectedLessonId(e.target.value)}
  //           className="mt-2 p-2 border rounded w-full"
  //         >
  //           <option value="">-- Select Lesson Topic --</option>
  //           {lessonTopics.map((topic) => (
  //             <option key={topic.Id} value={topic.Id}>{topic.topics}</option>
  //           ))}
  //         </select>
  //       </div>

  //       <div className="mb-4">
  //         <label className="block text-gray-700">Select Question Type:</label>
  //         <select
  //           value={questionSelectedType}
  //           onChange={(e) => {console.log(questionSelectedType) 
  //             return  setQustionSelectedType(e.target.value)}}
  //           className="mt-2 p-2 border rounded w-full"
  //         >
  //           <option value="">-- Select Queation Type--</option>
  //           {questionType.map((item,index) => (
  //             <option key={index} value={item.questionType}>{item.questionType}</option>
  //           ))}
  //         </select>
  //       </div>

  //       <div className="mb-4">
  //         <label className="block text-gray-700">Select Level:</label>
  //         <select
  //           value={selectedLevel}
  //           onChange={(e) => setSelectedLevel(e.target.value)}
  //           className="mt-2 p-2 border rounded w-full"
  //         >
  //           <option value="">-- Select Level --</option>
  //           {levels.map((level) => (
  //             <option key={level} value={level}>{level}</option>
  //           ))}
  //         </select>
  //       </div>

  //       <button
  //         onClick={generateQuestions}
  //         className="bg-blue-500 text-white p-2 rounded mr-2"
  //       >
  //         Generate Questions
  //       </button>
  //       <button
  //         onClick={handleSave}
  //         className="bg-green-500 text-white p-2 rounded"
  //       >
  //         Save Question
  //       </button>

  //       <div className="mt-4">
  //         <h2 className="text-lg font-bold">Generated Questions:</h2>
  //         <div>
  //           {questionsAndOptions.length > 0 ? (
  //             questionsAndOptions.map((item, index) => (
  //               <div key={index} className="mb-2">
  //                 <p className="font-semibold">{item.question}</p>
  //                 <ul className="list-disc pl-5">
  //                   {item.options.map((option, optionIndex) => (
  //                     <li key={optionIndex}>{option}</li>
  //                   ))}
  //                 </ul>
  //               </div>
  //             ))
  //           ) : (
  //             <p>No questions generated yet. Please select options and generate questions.</p>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // export default Generate;

  
  import React, { useState, useEffect } from 'react';

  const Generate = () => {
    const [yearData, setYearData] = useState([]);
    const [lessonTopics, setLessonTopics] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [questionTypes, setQuestionTypes] = useState([]);
    const [selectedQuestionType, setSelectedQuestionType] = useState(''); // New state variable for question type
    const [selectedLevel, setSelectedLevel] = useState('');
    const [generatedQuestions, setGeneratedQuestions] = useState([]); // State to store generated questions


    useEffect(() => {
      const fetchYearData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/genratequestionYeardata`, {
            method: 'POST',
            headers: {
              'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
              'Content-Type': 'application/json',
              'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw'
            },
          });
  
          const data = await response.json();
  
          if (data.status_code === "200") {
            setYearData(data.yearlist);
          } else {
            throw new Error('Failed to fetch year data');
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchYearData();
    }, []);
  
    const fetchLessonTopics = async (yearId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/getLessontopicList`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw',
          },
          body: JSON.stringify({ yearId })
        });
  
        const data = await response.json();
  
        if (data.status_code === "200") {
          setLessonTopics(data.yearlist); 
        } else {
          throw new Error('Failed to fetch lesson topics');
        }
      } catch (err) {
        setError(err.message);
      }
    };
  
    const fetchQuestionTypes = async (lessonId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/getQuestionTypeList`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw',
          },
          body: JSON.stringify({ lessonId })
        });
  
        const data = await response.json();
  
        if (data.status_code === "200") {
          setQuestionTypes(data.yearlist); 
        } else {
          throw new Error('Failed to fetch question types');
        }
      } catch (err) {
        setError(err.message);
      }
    };
  
    const handleYearChange = (e) => {
      const yearId = e.target.value;
      setSelectedYear(yearId);
      setSelectedTopic(''); 
      setSelectedQuestionType(''); // Reset selected question type
      setQuestionTypes([]); 
      if (yearId) {
        fetchLessonTopics(yearId); 
      } else {
        setLessonTopics([]); 
      }
    };
  
    const handleTopicChange = (e) => {
      const topicId = e.target.value;
      setSelectedTopic(topicId);
      setSelectedQuestionType(''); // Reset selected question type when the topic changes
      if (topicId) {
        fetchQuestionTypes(topicId); 
      } else {
        setQuestionTypes([]); 
      }
    };
  
    const handleQuestionTypeChange = (e) => {
      setSelectedQuestionType(e.target.value); // Update selected question type
    };
  
    const handleLevelChange = (e) => {
      setSelectedLevel(e.target.value); 
    };
  
    const handleGenerateQuestions = async () => {
      if (!selectedYear || !selectedTopic || !selectedQuestionType || !selectedLevel) {
        setError('Please select all fields before generating questions.');
        return;
      }
    
      const content = `I want 1 question based on ${selectedTopic} of ${selectedQuestionType} type for level ${selectedLevel} for the year ${selectedYear} on the basis of the VIC Australian curriculum. Don't generate anything extra at the start or end; just give me the question and four options.`;
    
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/geminiApi`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw',
          },
          body: JSON.stringify({ content })
        });
    
        const data = await response.json();
    
        if (data.status_code === "200") {
          // Extract the question from the response
          const generatedQuestion = data.data.candidates[0].content.parts[0].text;
          setGeneratedQuestions([generatedQuestion]); // Store the question as an array
          setError(null);
        } else {
          throw new Error('Failed to generate questions');
        }
      } catch (err) {
        setError(err.message);
      }
    };
    
    const handleSaveQuestion = async () => {
      console.log(generatedQuestions)
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/saveQuestion`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw',
          },
          body: JSON.stringify({question: generatedQuestions[0]})

        });

        console.log(response)

    }
    catch (err) {
      setError(err.message);
    }
  }
  
    
  
  
    if (loading) return <div className="text-center text-lg">Loading...</div>;
    if (error) return <div className="text-red-500 text-center">{error}</div>;
  
    return (
      <div className="max-w-md mx-auto p-6 border rounded-lg shadow-md bg-white">
        <h2 className="text-xl font-semibold mb-4">Select Year</h2>
        <select
          value={selectedYear}
          onChange={handleYearChange}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a year</option>
          {yearData.map((year) => (
            <option key={year.yearID} value={year.yearID}>
              {year.year} 
            </option>
          ))}
        </select>
  
        <h2 className="text-xl font-semibold mb-4">Select Lesson Topic</h2>
        <select
          value={selectedTopic}
          onChange={handleTopicChange}
          disabled={!selectedYear}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a topic</option>
          {lessonTopics.map((topic) => (
            <option key={topic.Id} value={topic.Id}>
              {topic.topics}
            </option>
          ))}
        </select>
  
        <h2 className="text-xl font-semibold mb-4">Select Question Type</h2>
        <select
          value={selectedQuestionType} // Bind the selected value
          onChange={handleQuestionTypeChange} // Add change handler
          disabled={!selectedTopic}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a question type</option>
          {questionTypes.map((questionType) => (
            <option key={questionType.questionType} value={questionType.questionType}>
              {questionType.questionType}
            </option>
          ))}
        </select>
  
        <h2 className="text-xl font-semibold mb-4">Select Level</h2>
        <select
          value={selectedLevel}
          onChange={handleLevelChange}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select level</option>
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
        </select>
  
        <button
        onClick={handleGenerateQuestions} // Call the API on button click
        className="w-full bg-blue-500 text-white font-semibold py-2 rounded hover:bg-blue-600"
      >
        Generate Questions
      </button>
      {generatedQuestions.length > 0 && (
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Generated Question</h2>
        <div className="border-b py-2">
          <p>{generatedQuestions[0]}</p>
        </div>
         <button onClick={handleSaveQuestion} className='w-full bg-blue-500 text-white font-semibold py-2 rounded hover:bg-blue-600'>Save Question</button>

      </div>
    )}

      </div>
    );
  };
  
  export default Generate;
  