import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const RequestTime = () => {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]); // For search functionality
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [timeInputs, setTimeInputs] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const {userid}=useParams()
    
     // State to hold search input

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/apiadmin/mathadmin/studentList`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
                        'Content-Type': 'application/json',
                        'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4OTkyMjE1LCJpYXQiOjE3Mjg5OTE5MTUsImp0aSI6IjczMGYzMDIxNGQxMzQ2OWJiYmNlNTRkMjU5ZTRjMTI1IiwidXNlcl9pZCI6Ik5vbmUifQ.-_y3cDpKqaFaPkuHBTlMlpk-iEOMsLQKpzV_j3ymyGA',
                    },
                    body: JSON.stringify({ Adminid: "65" }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();
                setStudents(result.data || []);
                setFilteredStudents(result.data || []); // Initialize filtered students
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleTimeChange = (studentId, timeType, value) => {
        setTimeInputs(prevInputs => ({
            ...prevInputs,
            [studentId]: {
                ...prevInputs[studentId],
                [timeType]: value,
            },
            
        }));
       
    };

    const handleAssignTime = async (studentId) => {
        const { from, to } = timeInputs[studentId] || {};

        console.log("from: ",from)
        console.log("to: ",to)

        if (!from || !to) {
            alert('Please enter valid time values for all fields.');
            return;
        }

        try {
            const response = await fetch('http://34.129.177.103/apiadmin/updatesignup', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
                    'Content-Type': 'application/json',
                    'jwt': filteredStudents.find(item => item.pk === Number(userid)).fields.jwtoken,
                },
                body: JSON.stringify({
                    id: userid,
                    appStartTime: from,
                    appEndTime: to,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to assign time');
            }

            const result = await response.json();
            console.log(result);
            alert('Time assigned successfully for student ID: ' + userid);

        } catch (error) {
            alert('Error: ' + error.message);
        }
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter students based on the search query (name or email)
        const filtered = students.filter(student => 
            student.fields.user_name.toLowerCase().includes(query) ||
            student.fields.email.toLowerCase().includes(query)
        );
        setFilteredStudents(filtered);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }
    console.log(filteredStudents.find(item => item.pk === Number(userid)).fields.jwtoken)
    console.log(filteredStudents.find(item => item.pk === Number(userid)))

    return (
        <div className="overflow-x-auto bg-[#FEF4DB] p-4">
            {/* <h1 className="text-lg mb-4">Assign Time</h1>
            <input
                type="text"
                placeholder="Search by name or email"
                value={searchQuery}
                onChange={handleSearchChange}
                className="border border-gray-300 p-2 rounded mb-4 w-[20%] text-sm"
            /> */}
            {filteredStudents.length > 0 ? (
                <table className="table-auto border-collapse border border-gray-300 text-xs">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-1">Name</th>
                            <th className="border border-gray-300 p-1">Email</th>
                            <th className="border border-gray-300 p-1">Year</th>
                            <th className="border border-gray-300 p-1">From (hh:mm:ss)</th>
                            <th className="border border-gray-300 p-1">To (hh:mm:ss)</th>
                            <th className="border border-gray-300 p-1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {filteredStudents.map(student => ( */}
                            <tr >
                                <td className="border border-gray-300 p-1">{filteredStudents.find(item => item.pk === Number(userid)).fields.user_name}</td>
                                <td className="border border-gray-300 p-1">{filteredStudents.find(item => item.pk === Number(userid)).fields.email}</td>
                                <td className="border border-gray-300 p-1">{filteredStudents.find(item => item.pk === Number(userid)).fields.year}</td>
                                <td className="border border-gray-300 p-1">
                                    <input
                                        type="time"
                                        value={timeInputs[filteredStudents.find(item => item.pk === Number(userid))]?.from || ''}
                                        onChange={(e) => handleTimeChange(filteredStudents.find(item => item.pk === Number(userid)), 'from', e.target.value)}
                                        className="border border-gray-300 p-1 rounded w-full text-xs"
                                    />
                                </td>
                                <td className="border border-gray-300 p-1">
                                    <input
                                        type="time"
                                        value={timeInputs[filteredStudents.find(item => item.pk === Number(userid))]?.to || ''}
                                        onChange={(e) => handleTimeChange(filteredStudents.find(item => item.pk === Number(userid)), 'to', e.target.value)}
                                        className="border border-gray-300 p-1 rounded w-full text-xs"
                                    />
                                </td>
                                <td className="border border-gray-300 p-1">
                                    <button
                                        onClick={() => handleAssignTime(filteredStudents.find(item => item.pk === Number(userid)))}
                                        className="bg-blue-500 text-white p-1 rounded hover:bg-blue-600 text-xs"
                                    >
                                        Assign Time
                                    </button>
                                </td>
                            </tr>
                        {/* ))} */}
                    </tbody>
                </table>
            ) : (
                <p>No students found</p>
            )}
        </div>
    );
};

export default RequestTime;
