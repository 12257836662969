// Sidebar.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { llogo } from "./assets";
const Sidebar = ({ selectedCard, setSelectedCard, handleInsightRedirect, handleSignupRedirect }) => {
  const navigate = useNavigate();

  return (
    <div className="sidebar">
      <div className="logo">
        <img
          src={llogo}
          alt="Logo"
          className="card-image"
        />
      </div>
      <div className="w-[100%]">
        <div className="card-container">
          <div
          >
            <p className="insight-button w-[100%]" onClick={() => navigate('/dashboard')}>
              Dashboard
            </p>
          </div>
        </div>
        <button className="insight-button w-[100%]" onClick={handleInsightRedirect}>
          Insights
        </button>
        <button className="insight-button w-[100%]" onClick={() => navigate('/assign')}>
          Assign Time
        </button>
        <button className="insight-button w-[100%]" onClick={() => navigate('/generate')}>
          Generate Question
        </button>
      </div>
      <button className="logout-button" onClick={handleSignupRedirect}>
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
