import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./StudentDetails.css";

const StudentDetails = () => {
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [years, setYears] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  

 
  const AUTH_TOKEN = "a6039607dfd014db1c2ff40d25f14c4a715f0282";
  const JWT_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch student details
        const studentResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/studentList`,
          {
            method: "POST",
            headers: {
              Authorization: `Token ${AUTH_TOKEN}`,
              "Content-Type": "application/json",
              jwt: JWT_TOKEN,
            },
            body: JSON.stringify({ Adminid: "65" }),
          }
        );

        if (!studentResponse.ok) {
          throw new Error(
            ` Question not attempted yet ${studentResponse.status} ${studentResponse.statusText}`
          );
        }

        const studentResult = await studentResponse.json();
        const studentData = studentResult.data.find(
          (stu) => stu.pk === parseInt(id, 10)
        );
        setStudent(studentData);
        console.log(studentData)

        // Fetch years
        const yearResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/studentyear`,
          {
            method: "POST",
            headers: {
              Authorization: `Token ${AUTH_TOKEN}`,
              "Content-Type": "application/json",
              jwt: JWT_TOKEN,
            },
            body: JSON.stringify({ Studentid: id }),
          }
        );

        if (!yearResponse.ok) {
          throw new Error(
            `Question not attempted yet ${yearResponse.status} ${yearResponse.statusText}`
          );
        }

        const yearResult = await yearResponse.json();
        const yearsData = yearResult.data || [];
        setYears(yearsData);

        // Retrieve selected year from local storage
        const storedYearId = localStorage.getItem(`selectedYear_${id}`);
        const storedYearIdNumber = storedYearId
          ? parseInt(storedYearId, 10)
          : null;
        if (
          storedYearIdNumber &&
          yearsData.some((year) => year.pk === storedYearIdNumber)
        ) {
          setSelectedYearId(storedYearIdNumber);
        } else if (yearsData.length > 0) {
          setSelectedYearId(yearsData[0].pk);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // const getTitleArrayForYear = (yearId) => {
  //   switch (yearId) {
  //     case 1: // Assuming foundation year has id 1
  //       return foundationTitles;
  //     case 2: // Assuming year 1 has id 2
  //       return year1Titles;
  //     case 3: // Assuming year 2 has id 3
  //       return year2Titles;
  //     case 4: // Assuming year 3 has id 4
  //       return year3Titles;
  //     case 5: // Assuming year 1 has id 2
  //       return year4Titles;
  //     case 6: // Assuming year 2 has id 3
  //       return year5Titles;

  //     case 7: // Assuming year 2 has id 3
  //       return year6Titles;

  //     default:
  //       return [];
  //   }
  // };

  useEffect(() => {
    if (selectedYearId !== null) {
      const fetchLessons = async () => {
        try {
          const lessonResponse = await fetch(
            `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/studentlesson`,
            {
              method: "POST",
              headers: {
                Authorization: `Token ${AUTH_TOKEN}`,
                "Content-Type": "application/json",
                jwt: JWT_TOKEN,
              },
              body: JSON.stringify({ Yearid: selectedYearId, Studentid: id }),
            }
          );

          if (!lessonResponse.ok) {
            throw new Error(
              `Failed to fetch student lessons: ${lessonResponse.status} ${lessonResponse.statusText}`
            );
          }

          const lessonResult = await lessonResponse.json();
          setLessons(lessonResult.data || []);
        } catch (err) {
          console.error("Error fetching lessons:", err);
          setError(err.message);
        }
      };

      fetchLessons();
    }
  }, [selectedYearId, id]);

  const handleYearChange = (event) => {
    const newYearId = Number(event.target.value);
    if (newYearId !== selectedYearId) {
      setSelectedYearId(newYearId);
      localStorage.setItem(`selectedYear_${id}`, newYearId);
    }
  };

  const handleViewDetails = (lessonId) => {
    navigate(`/student/${id}/report/${lessonId}`);
  };

  if (loading) return <p className="loading-message">Loading...</p>;
  if (error) return <p className="error-message">Error: {error}</p>;

  // const titleArray = getTitleArrayForYear(selectedYearId);
  console.log(selectedYearId);
  console.log("dsfsd",lessons);
  return (
    <div className="stuDetails">
      <img
        src="http://34.129.177.103/media/adminimage/Frame1141.png"
        alt="Logo"
        style={{
          width: "250px",
          height: "auto",
        }}
      />
      <div className="student-details">
        {student && (
          <div className="flex flex-col items-center justify-center gap-10">
            <div className="bg-student-detail-bg rounded-md p-5 w-[80%] ">
              <div className="flex items-center gap-4">
                <img src="/day-week.png" alt=""></img>
                <h3
                  style={{
                    color: "#000",
                    fontfamily: "Poppins",
                    fontsize: "22px",
                    fontstyle: "normal",
                    fontweight: "600",
                    lineheight: "normal",
                    letterspacing: "-0.22px",
                  }}
                >
                  Student Details
                </h3>
              </div>

              <table className="details-table ">
                <div className="flex items-center">
                  <img className="w-44" src={student.fields.profilephoto}></img>
                  {/* <tbody >
                    
                    <div className="bg-white rounded-lg ">
                      <tr className="w-full bg-white">
                        <td
                          className="bg-black text-white rounded-l-lg px-2 py-2 rounded-r-lg"
                        >
                          Username
                        </td>
                        <td className="bg-white text-black rounded-r-lg px-2 py-2">
                          {student.fields.user_name || "N/A"}
                        </td>
                      </tr>
                    </div>
                    
                    <tr>
                      <td>Email</td>
                      <td>{student.fields.email || "N/A"}</td>
                    </tr>
                    <div/>
                    <tr>
                    
                      <td>Years</td>
                      <td>
                        {years.length > 0 ? (
                          <select
                            className="border-button-bg text-button-bg border-[1px] px-[10px] py-[3px] rounded-md "
                            onChange={handleYearChange}
                            value={selectedYearId || ""}
                          >
                            <option value="">Select Year</option>
                            {years.map((year) => (
                              <option key={year.pk} value={year.pk}>
                                {year.fields.year}
                              </option>
                            ))}
                          </select>
                        ) : (
                          "No records yet"
                        )}
                      </td>
                    </tr>
                  </tbody> */}
                  <tbody>
                    {/* Username Row */}
                    <div className="bg-white rounded-lg mb-4">
                      {" "}
                      {/* Added mb-4 for gap */}
                      <tr className="w-full bg-white">
                        <td
                          className="bg-black text-white rounded-lg px-2 py-2"
                          style={{ paddingRight: "40px" }}
                        >
                          Username
                        </td>
                        <td className="bg-white text-black rounded-r-lg px-2 py-2">
                          {student.fields.user_name || "N/A"}
                        </td>
                      </tr>
                    </div>

                    {/* Email Row */}
                    <div className="bg-white rounded-lg mb-4">
                      {" "}
                      {/* Added mb-4 for gap */}
                      <tr className="w-full bg-white">
                        <td
                          className="bg-black text-white rounded-lg px-2 py-2"
                          style={{ paddingRight: "74px" }}
                        >
                          Email
                        </td>
                        <td className="bg-white text-black rounded-r-lg px-2 py-2">
                          {student.fields.email || "N/A"}
                        </td>
                      </tr>
                    </div>

                    {/* Years Row */}
                    <div className="bg-white rounded-lg">
                      {" "}
                      {/* No margin for the last row */}
                      <tr className="w-full bg-white">
                        <td
                          className="bg-black text-white rounded-lg px-2 py-2 pr-[18px]"
                          style={{ paddingRight: "74px" }}
                        >
                          Years
                        </td>
                        <td className="bg-white text-black rounded-r-lg px-2 py-2">
                          {years.length > 0 ? (
                            <select
                              className="border-button-bg text-button-bg border-[1px] px-[10px] py-[3px] rounded-md"
                              onChange={handleYearChange}
                              value={selectedYearId || ""}
                            >
                              <option value="">Select Year</option>
                              {years.map((year) => (
                                <option key={year.pk} value={year.pk}>
                                  {year.fields.year}
                                </option>
                              ))}
                            </select>
                          ) : (
                            "No records yet"
                          )}
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </div>
              </table>
            </div>

            <div className="bg-white rounded-md p-5 w-[80%]">
              <h2
                style={{
                  color: "#000",
                  fontfamily: "Poppins",
                  fontsize: "22px",
                  fontstyle: "normal",
                  fontweight: "600",
                  lineheight: "normal",
                  letterspacing: "-0.22px",
                }}
              >
              </h2>
              {lessons.length > 0 ? (
                <table className="lessons-table ">
                  <tbody>
                    <tr>
                      <th>Lesson</th>
                      <th>Topic</th>
                      <th>Performance</th>
                      <th>Status</th>
                      <th>Video Count</th>
                      <th>Actions</th>
                    </tr>
                    {lessons.map((lesson, index) => (
                      <tr key={lesson.pk}>
                        <td>{lesson.fields.lession || "N/A"}</td>
                        <td>{(lesson.fields.lessonTopics).replace("#",", ") || 'N/A'}</td>
                        {/* <td>{titleArray[index] || "N/A"}</td> */}

                        <td className="">
                          {lesson.fields.performance
                            ? lesson.fields.performance.toFixed(2) + "%"
                            : "0.0%"}
                        </td>

                        {/* <td>{lesson.fields.performance ? lesson.fields.performance.toFixed(2) + '%' : '0.0%'}</td> */}
                        <td>{lesson.fields.status || "N/A"}</td>
                        <td>{lesson.fields.videocount}</td>
                        <td>
                          {/* <button
                            className={`border-button-bg text-button-bg border-[1px] px-[10px] py-[3px] rounded-md ${
                              lesson.fields.status.toLowerCase() ===
                              "not attempted"
                                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                                : "bg-blue-500 text-white"
                            }`}
                            onClick={() => handleViewDetails(lesson.pk)}
                            disabled={
                              lesson.fields.status.toLowerCase() ===
                              "not attempted"
                            } // Disable if status is 'Not Attempted'
                          >
                            View Details
                          </button> */}
                          <button
                            className={`flex w-[124.135px] p-[4px_12px] justify-center items-center gap-[10px] rounded-[4px] border border-[#08059B] text-[#08059B] font-poppins text-[14px] font-medium leading-normal tracking[-0.14px]" ${
                              lesson.fields.status.toLowerCase() ===
                              "not attempted"
                                ? "bg-gray-00 text-gray-400 border-gray-400 cursor-not-allowed"
                                : ""
                            }`}
                            onClick={() => handleViewDetails(lesson.pk)}
                            disabled={
                              lesson.fields.status.toLowerCase() ===
                              "not attempted"
                            } // Disable if status is 'Not Attempted'
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No lessons found</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentDetails;
